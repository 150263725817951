import './MainLayout.scss';

import { Avatar, Layout, Menu, Typography } from 'antd';
import {
    ShopOutlined,
    // KeyOutlined,
    BarChartOutlined,
    // UserOutlined,
    LogoutOutlined,
    UserOutlined
} from '@ant-design/icons';

import Sider from 'antd/es/layout/Sider';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useLogout } from '../../hooks';
import './MainLayout.scss';
import { AppButton } from '../../components/buttons';
import { FlexBox } from '../../components/layouts';
import { useRecoilState } from 'recoil';
import { accountInfoState } from '@/components/state';

export function MainLayout() {
  const [account] = useRecoilState(accountInfoState);
    
    const logout = useLogout();

     const location = useLocation();

    const items = [
		{ label: 'Báo cáo', key: '/report', icon: <BarChartOutlined />, url: '/report' },
		{ label: 'Salon', key: '/salon', icon: <ShopOutlined />, url: '/salon' },
        { label: 'Tài khoản', key: '3', icon: <UserOutlined />, url: '/account' },
		// { label: 'Phân quyền', key: '4', icon: <KeyOutlined />, url: '/authorization' },
	];

    const key = items.find((o) => location.pathname.startsWith(o.key))?.key ?? '/report';

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
            >
                <FlexBox direction='column'>
                    <FlexBox direction='column'>
                        <img style={{ maxWidth: 150, height: 'auto', maxHeight: 170, margin: 20 }} src="https://my.easysalon.vn/static/assets/logo/logo-my.png" alt="logo" />
                        <Menu theme="dark" defaultSelectedKeys={[key]} mode="inline" >
                            {items.map(item => (
                                <Menu.Item key={item.key} icon={item.icon}>
                                    <Link to={item.url}>{item.label}</Link>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </FlexBox>
                    <div className='account'>
						<div className='account-info'>
							<Avatar
                                className='account-avatar'
								icon={<UserOutlined />}
								style={{ backgroundColor: '#fff', color: '#1677ff' }}
							/>
                            <Typography.Text>{account?.name}</Typography.Text>
						</div>
						<AppButton
							type='text'
							className='logout-button'
							onClick={logout}
							icon={<LogoutOutlined />}
						>
							Đăng xuất
						</AppButton>
					</div>
                </FlexBox>
            </Sider>
            <Layout style={{ marginLeft: 200 }}>
                <Outlet />
            </Layout>
        </Layout>
    );
}
